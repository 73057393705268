import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Container from '../components/Container'
import { PageHeader } from '../types/definitions'
import ArticleList from '../components/ArticleList'

type ArticlePageProps = {
  data: {
    about: {
      frontmatter: {
        header: PageHeader
        footer: {
          showCTA: boolean
        }
        seo: { title: string; description: string }
      }
    }
    articles: {
      nodes: { fields: { slug: string }; frontmatter: { header: PageHeader } }[]
    }
  }
}

const ArticlePage: FunctionComponent<ArticlePageProps> = ({ data }) => {
  const { header, seo, footer } = data.about.frontmatter

  const articles = data.articles.nodes.map((node) => {
    return {
      title: node.frontmatter.header.title,
      excerpt: node.frontmatter.header.openingContent,
      image: node.frontmatter.header.image,
      slug: node.fields.slug,
    }
  })

  return (
    <Layout header={header} footer={footer} seo={seo}>
      <Container bg="white">
        <ArticleList articles={articles} />
      </Container>
    </Layout>
  )
}

export default ArticlePage

export const pageQuery = graphql`
  query Articles {
    about: mdx(frontmatter: { templateKey: { eq: "articles-page" } }) {
      frontmatter {
        footer {
          showCTA
        }
        ...header
        seo {
          description
          title
        }
      }
    }
    articles: allMdx(
      filter: { frontmatter: { templateKey: { eq: "article-page" } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          ...header
        }
      }
    }
  }
`
